import { createVuetify } from "vuetify"
import * as components from "vuetify/components"
import * as directives from "vuetify/directives"
import "vuetify/styles"
import theme from "@/utils/theme"

const wbdark = {
  dark: true,
  colors: {
    primary: "#0082c8"
  }
}

const wblight = {
  dark: false,
  colors: {
    primary: "#0082c8"
  }
}

const vuetify = createVuetify({
  theme: {
    defaultTheme: theme.isDark ? "dark" : "light",
    themes: {
      dark: wbdark,
      light: wblight
    }
  },
  directives,
  components: {
    ...components
  }
})

async function loadFonts() {
  const webFontLoader = await import(/* webpackChunkName: "webfontloader" */ "webfontloader")
  webFontLoader.load({
    google: {
      families: ["Roboto:100,300,400,500,700,900&display=swap", "Roboto+Condensed:100,300,400,500,700,900&display=swap"]
    }
  })
}

export function registerVuetifyPlugin(app) {
  loadFonts()
  app.use(vuetify)
}
