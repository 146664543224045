<template>
  <div style="overflow: hidden">
    <div class="section-header">
      <h3>Debt</h3>
      <StandaloneScoreInput :issuer="issuer" dbkey="debt_structure.score"></StandaloneScoreInput>

      <div v-if="!expandedView" class="section-header-right ml-sm-0 ml-md-auto">
        <div>
          <v-tooltip location="bottom" activator="parent">
            <div>Click to upload excel file to update. Worksheet {{ excelSheetName }} is required</div>
          </v-tooltip>
          <v-file-input
            density="default"
            prepend-icon="mdi-cloud-upload-outline"
            accept=".xls,.xlsx,application/msexcel,application/vnd.ms-excel"
            :disabled="uploadErrorMessage !== ''"
            @update:model-value="upload"
          ></v-file-input>
        </div>

        <v-btn-toggle v-if="meta.financials !== 'Y'" v-model="viewType" mandatory rounded density="compact">
          <v-btn size="x-small">Data</v-btn>
          <v-btn size="x-small">Comments</v-btn>
        </v-btn-toggle>
      </div>
    </div>

    <div
      v-if="viewType === 0"
      class="content-card"
      :style="{ overflow: 'auto', height: expandedView ? 'auto' : '360px' }"
    >
      <v-alert
        v-if="uploadErrorMessage"
        density="compact"
        text
        closable
        type="error"
        style="font-size: 12px"
        @update:model-value="uploadErrorMessage = ''"
      >
        {{ uploadErrorMessage }}
      </v-alert>

      <v-alert
        v-if="debtData.length === 0 && fundingData.length === 0"
        text
        density="compact"
        type="info"
        color="info"
        style="font-size: 12px"
      >
        Please click
        <v-icon>mdi-cloud-upload-outline</v-icon>
        on the top to upload the data for current issuer
      </v-alert>

      <div v-else>
        <highcharts
          v-if="Array.isArray(debtData) && debtData.length > 0"
          :options="chartOptionsDebtMaturities"
        ></highcharts>
        <div v-else>
          <DocContentEditor
            :show-byline="false"
            :resource-path="`/api/corp_crm_models/${issuer}`"
            remote-key="debt_structure.comments"
          />
        </div>
      </div>
    </div>

    <div
      v-if="expandedView || viewType === 1"
      class="content-card pt-1"
      :style="`height: expandedView? 'auto':'360px'`"
    >
      <DocContentEditor
        :show-byline="false"
        :resource-path="`/api/corp_crm_models/${issuer}`"
        remote-key="debt_structure.comments"
      />
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx"
import { pick } from "lodash-es"
import Chart from "@wbim/highcharts-themed"
import EventBus from "@/utils/eventbus.js"
import DocContentEditor from "@/components/common/DocContentEditor.vue"
import StandaloneScoreInput from "@/components/common/StandaloneScoreInput.vue"

export default {
  components: { DocContentEditor, StandaloneScoreInput, highcharts: Chart },

  inject: ["apiRepo", "theme"],

  props: {
    issuer: { type: String, required: true },
    expandedView: { type: Boolean, default: false }
  },

  data() {
    return {
      viewType: 0,
      excelSheetName: "Upload Debt",
      uploadErrorMessage: "",
      debtData: [],
      fundingData: [],
      meta: {}
    }
  },

  computed: {
    chartOptionsDebtMaturities() {
      return {
        chart: { type: "column", height: 342, borderWidth: 0 }, //height need to be adjusted to negate the outer content-card style
        title: { text: "Debt Maturities" },
        legend: { enabled: true },
        xAxis: { categories: this.debtData.slice(1).map((d) => String(d[0])) },
        yAxis: {
          title: {
            text: this.meta.currency ? `${this.meta.currency} in ${this.meta.units}` : ""
          },
          stackLabels: {
            format: "{total:.0f}"
          }
        },
        plotOptions: {
          column: {
            stacking: "normal"
          }
        },
        series: ["Bank", "Bond", "Other"].map((d, i) => ({
          name: d,
          data: this.debtData.slice(1).map((row) => row[i + 1])
        }))
      }
    },

    chartOptionsFundingStructure() {
      return {
        chart: { type: "pie", height: 316, borderWidth: 0 },
        title: { text: "Funding Structure" },
        tooltip: { enabled: false },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              useHTML: true,
              formatter() {
                const total = this.series.yData.reduce((acc, cur) => acc + cur, 0)
                const pct = ((this.y / total) * 100).toFixed(0) + "%"
                return `${this.key}: ${pct}<br> ${this.y.toFixed(0)}`
              }
            }
          }
        },
        series: [
          {
            name: "Funding Structure",
            data: this.fundingData
              .filter((d) => d[0] !== "Total")
              .map((d) => ({
                name: d[0],
                y: d[2],
                dataLabels: { distance: d[2] / this.fundingData[5][2] > 0.2 ? "-30%" : 8 }
              }))
          }
        ]
      }
    }
  },

  async mounted() {
    this.$watch(
      "$route.params",
      ({ issuer }) => {
        if (issuer && this.$route.name === "corp-risk-model") {
          this.init(issuer)
        }
      },
      { immediate: true }
    )
  },

  methods: {
    async init(issuer) {
      const api = await this.apiRepo.makeServiceAPIClient()
      const { data, status } = await api.get(`/api/corp_crm_models/${issuer}?path=debt_structure.details`)

      if (status === 200 && data) {
        this.meta = data.meta
        this.debtData = data.debtData
        this.fundingData = data.fundingData
        EventBus.$emit("reload-funding", { meta: data.meta, fundingData: data.fundingData })
      } else {
        this.docContent = `<div class='content-card' style="height: ${
          this.expandedView ? "auto" : "360px"
        }" >failed to load the docs from remote</div>`
      }
    },

    upload(file) {
      if (!file) {
        vm.uploadErrorMessage = `Please choose a valid file.`
        return
      }

      const vm = this
      const reader = new FileReader()
      reader.onload = async function (e) {
        const data = e.target.result
        const workbook = XLSX.read(data, { type: "array" })
        const sheet = workbook.Sheets[vm.excelSheetName]
        if (sheet) {
          const meta = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: true, range: "A1:B4" })
          vm.meta = {
            issuer: meta[0][1],
            currency: meta[1][1],
            units: meta[2][1],
            financials: meta[3][1]
          }

          if (String(vm.meta.issuer) !== vm.issuer) {
            vm.uploadErrorMessage = "Incorrect model uploaded for current issuer."
            return
          }
          vm.uploadErrorMessage = ""

          const debtData = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: true, range: "A6:E12", blankrows: false })
          const fundingDataRaw = XLSX.utils.sheet_to_json(sheet, { header: 1, range: "A17:C40" })
          const firstDataCell = String(fundingDataRaw[0][0]).trim()

          //Deposits for financial corps
          //Bank Debt for Debt Structure for non-financial corps
          if (firstDataCell !== "Deposits" && firstDataCell !== "Bank Debt") {
            vm.uploadErrorMessage =
              "Cell range mismatch, make sure the valid data range(exclude header) starging from Row 17"
            return
          }

          vm.debtData = debtData
          vm.fundingData = fundingDataRaw.filter((d) => d[0] && d[0].trim() !== "")

          const payload = pick(vm, ["meta", "debtData", "fundingData"])

          const api = await vm.apiRepo.makeServiceAPIClient()
          const { status } = await api.patch(`/api/corp_crm_models/${vm.issuer}`, {
            path: "debt_structure.details",
            value: payload,
            update_crm_date: true
          })
          if (status !== 200) {
            vm.uploadErrorMessage = "failed to upload financial data"
          } else {
            EventBus.$emit("reload-funding", { meta: vm.meta, fundingData: vm.fundingData })
          }
        } else {
          vm.uploadErrorMessage = `Invalid sheet name. Please ensure sheet name ${vm.excelSheetName} exists in the uploaded file.`
          return
        }
      }
      reader.readAsArrayBuffer(file)
    }
  }
}
</script>
